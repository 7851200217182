var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat"},[_c('div',{class:['TUI-chat', !_vm.isPC && 'TUI-chat-h5']},[(!_vm.currentConversationID)?_c('div',{class:['TUI-chat-default', !_vm.isPC && 'TUI-chat-h5-default']},[_vm._t("default")],2):_vm._e(),(_vm.currentConversationID)?_c('div',{class:['TUI-chat', !_vm.isPC && 'TUI-chat-h5']},[_c('ChatHeader',{class:[
          'TUI-chat-header',
          !_vm.isPC && 'TUI-chat-H5-header',
          !_vm.isToolbarShow && 'TUI-chat-uniapp-header',
        ],on:{"closeChat":_vm.closeChat,"startOrderCourse":_vm.startOrderCourse}}),_c('MessageList',{class:[
          'TUI-chat-message-list',
          !_vm.isPC && 'TUI-chat-h5-message-list',
        ],attrs:{"isGroup":_vm.isGroup,"groupID":_vm.groupID},on:{"handleEditor":_vm.handleEditor}}),_c('MessageInputToolbar',{directives:[{name:"show",rawName:"v-show",value:(_vm.isToolbarShow),expression:"isToolbarShow"}],class:[
          'TUI-chat-message-input-toolbar',
          !_vm.isPC && 'TUI-chat-h5-message-input-toolbar',
        ],on:{"insertEmoji":_vm.insertEmoji}}),_c('MessageInput',{ref:"messageInputRef",class:[
          'TUI-chat-message-input',
          !_vm.isPC && 'TUI-chat-h5-message-input',
          !_vm.isToolbarShow && 'TUI-chat-uni-message-input',
          _vm.isWeChat && 'TUI-chat-wx-message-input',
        ],attrs:{"isMuted":false,"muteText":"您已被管理员禁言","placeholder":"请输入消息"},on:{"handleToolbarListShow":_vm.handleToolbarListShow}})],1):_vm._e(),(!_vm.isToolbarShow && _vm.isGroup && _vm.groupManageExt)?_c('div',{staticClass:"group-profile",on:{"click":_vm.handleGroup}},[_vm._v(" "+_vm._s(_vm.groupManageExt.text)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }