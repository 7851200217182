var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{ref:"htmlRefHook",staticClass:"message-bubble",class:[_setup.message.flow === 'in' ? '' : 'reverse']},[_c('img',{staticClass:"avatar",attrs:{"src":_setup.message.avatar ||
      'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png',"onerror":"this.src='https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'"}}),_c('main',{staticClass:"message-area"},[(_setup.message.flow === 'in' && _setup.message.conversationType === 'GROUP')?_c('label',{staticClass:"name"},[_vm._v(" "+_vm._s(_setup.messageShowName)+" ")]):_vm._e(),_c('div',{class:[
        'content',
        `content-${_setup.message.flow}`,
        _setup.message.type === _setup.TYPES.MSG_IMAGE ? 'content-image' : '',
        _setup.message.type === _setup.TYPES.MSG_VIDEO ? 'content-video' : '',
      ]},[_vm._t("default")],2)]),(_setup.message.status === 'fail')?_c('label',{staticClass:"message-label fail",on:{"click":function($event){return _setup.resendMessage()}}},[_vm._v("!")]):_vm._e(),(_setup.message.status === 'unSend')?_c(_setup.Icon,{staticClass:"message-label",attrs:{"file":_setup.loading,"width":'15px',"height":'15px'}}):_vm._e(),(
      _setup.message.conversationType === 'C2C' &&
      _setup.message.flow == 'out' &&
      _setup.message.status === 'success'
    )?_c('label',{staticClass:"message-label",class:[!_setup.message.isPeerRead && 'unRead']},[(!_setup.message.isPeerRead)?_c('span',[_vm._v("未读")]):_c('span',[_vm._v("已读")])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }