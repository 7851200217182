<template>
  <div :class="[isPC ? 'home' : 'home-h5']" :id="isPC ? 'preloadedImages' : ''">
    <!-- <Header class="home-header" v-if="isPC">
      <template v-slot:right>
        <el-dropdown>
          <span class="dropdown">
            <i class="icon icon-global"></i>
            <label>{{ TUITranslateService.t("当前语言") }}</label>
            <i class="icon icon-arrow-down"></i>
          </span>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="zh_cn" class="language-item">简体中文</el-dropdown-item>
              <el-dropdown-item command="en" class="language-item">
                English(敬请期待)
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </template>
    </Header> -->
    <!-- 这里暂时只考虑PC端 -->
    <div :class="[isPC ? 'menu' : '']" v-if="isPC">
      <Menu class="home-menu" v-show="false"> </Menu>
    </div>
    <main class="home-main" :class="[!isPC ? '' : 'home-main-open']" v-if="isPC">
      <div class="home-main-box">
        <div class="home-TUIKit">
          <!-- 聊天左栏 -->
          <!-- <div class="setting">
            <main class="setting-main">
              <aside class="userInfo">
                <img class="avatar" src="https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png" />
                <div class="userInfo-main">
                  <Profile></Profile>
                </div>
              </aside>
              <ul class="setting-main-list">
                <li class="setting-main-list-item" :class="[currentTab === 'message' && 'selected']" @click="selectModel('message')">
                  <i :class="[
                      'icon',
                      `icon-message${
                        currentTab === 'message' ? '-selected' : ''
                      }`,
                    ]"></i>
                </li>
                <li class="setting-main-list-item" :class="[currentTab === 'relation' && 'selected']" @click="selectModel('relation')">
                  <i :class="[
                      'icon',
                      `icon-relation${
                        currentTab === 'relation' ? '-selected' : ''
                      }`,
                    ]"></i>
                </li>
              </ul>
            </main>
            <div class="setting-footer">
              <i class="icon icon-setting" @click="openShowMore"></i>
              <div class="setting-more" v-if="showMore">
                <div class="showmore">
                  <Profile displayType="setting" :showSetting="showMore" @update:showSetting="showMore = false"></Profile>
                </div>
                <div class="moreMask" @click.self="openShowMore"></div>
              </div>
            </div>
          </div> -->
          <div class="home-TUIKit-main" v-show="currentTab === 'message'">
            <div class="home-conversation">
              <TUIConversation :class="['TUIConversation', !isPC && 'TUIConversation-h5']" @handleSwitchConversation="handleSwitchConversation"></TUIConversation>
            </div>
            <div class="home-chat">
              <!-- 聊天入口在这里，注意需要当前的 conversationID 才能打开 chat -->
              <TUIChat :class="['TUIChat', !isPC && 'TUIChat-h5']" @closeChat="closeChat" @startOrderCourse="startOrderCourse">
              </TUIChat>
              <TUIGroup class="group" />
            </div>
            <TUIContact display-type="selectFriend" />
          </div>
          <div :class="[currentTab === 'relation' && 'home-TUIKit-main']" v-show="currentTab === 'relation'">
            <TUIContact display-type="contactList" @switchConversation="currentTab = 'message'" />
          </div>
        </div>
      </div>
    </main>
    <!-- h5 -->
    <main class="home-h5-main" v-if="!isPC">
      <div class="home-h5-main" v-if="!currentConversationID">
        <main class="home-h5-main" v-if="currentTab === 'message'">
          <TUIConversation></TUIConversation>
          <TUIContact display-type="selectFriend"></TUIContact>
        </main>
        <main class="home-h5-main" v-if="currentTab === 'relation'">
          <TUIContact display-type="contactList" @switchConversation="currentTab = 'message'" />
        </main>
        <main class="home-h5-main" v-if="currentTab === 'profile'">
          <Profile display-type="all" />
        </main>
        <footer class="nav">
          <ul class="nav-list">
            <li class="nav-list-item" v-for="(item, index) in navList" :key="index" @click.stop="selectModel(item.name)">
              <i class="icon" :class="[
                  'icon-' +
                    (currentTab === item.name
                      ? `${item.icon}-selected`
                      : `${item.icon}-real`),
                ]"></i>
              <label :class="[currentTab === item.name && `selected`]">{{
                `${item.label}`
              }}</label>
            </li>
          </ul>
        </footer>
      </div>
      <!-- 聊天入口在这里，注意需要当前的 conversationID 才能打开 chat -->
      <TUIChat :class="['TUIChat', !isPC && 'TUIChat-h5']" @closeChat="closeChat" @startOrderCourse="startOrderCourse" v-else>
      </TUIChat>
      <TUIGroup class="group-h5" />
    </main>
    <Drag :show="showCall" :domClassName="
        !isMinimized ? 'callkit-drag-container' : 'callkit-drag-container-mini'
      " ref="dragRef">
      <TUICallKit :class="[
          isMinimized && 'callkit-drag-container-mini',
          !isMinimized && 'callkit-drag-container',
          !isPC && 'callkit-drag-container-H5',
        ]" :allowedMinimized="true" :allowedFullScreen="false" :beforeCalling="beforeCalling" :afterCalling="afterCalling" :onMinimized="onMinimized" />
    </Drag>
    <orderCourse ref="orderCourse"></orderCourse>
  </div>
</template>
<script>
import {
  TUIGlobal,
  TUIStore,
  StoreName,
  TUIConversationService
} from "@tencentcloud/chat-uikit-engine";
import { TUIChat, TUIConversation, TUIContact, TUIGroup } from "../TUIKit";
import { ref } from "../TUIKit/adapter-vue";
import Header from "../components/Header.vue";
import Menu from "../components/Menu.vue";
import Profile from "./Profile.vue";
import { TUICallKit } from "@tencentcloud/call-uikit-vue2";
import Drag from "../TUIKit/components/common/Drag";
import orderCourse from './components/orderCourse.vue'
import { mapState } from 'vuex'
export default {
  name: "Home",
  components: {
    TUIChat,
    TUIConversation,
    TUIContact,
    TUIGroup,
    Drag,
    TUICallKit,
    Profile,
    orderCourse
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      toInfo: state => state.toInfo,
      isTeacher: state => state.isTeacher,
      courseInfo: state => state.courseInfo,
    })
  },
  data() {
    return {
      isPC: TUIGlobal.getPlatform() === "pc",
      currentTab: "message",
      currentConversationID: "",
      showMore: false,
      showCall: false,
      isMinimized: false,
      dragRef: null,
      navList: [
        {
          icon: "message",
          name: "message",
          label: "消息",
        },
        {
          icon: "relation",
          name: "relation",
          label: "通讯录",
        },
        {
          icon: "profile",
          name: "profile",
          label: "个人中心",
        },
      ],
    };
  },
  methods: {
    handleSwitchConversation(conversationID) {
      console.log('handleSwitchConversation',conversationID)
      const data = {}
      data.type = 'handleSwitchConversation';
      data.conversationID = conversationID
      window.parent.postMessage(data, '*')
    },
    startOrderCourse() {
      const data = {}
      data.type = 'preOrder';
      data.userInfo = this.userInfo;
      data.toInfo = this.toInfo;
      data.isTeacher = this.isTeacher;
      data.courseInfo = this.courseInfo;
      window.parent.postMessage(data, '*')
    },
    selectModel(modelName) {
      this.currentTab = modelName;
    },
    closeChat(closedConversationID) {
      console.log(`conversation ${closedConversationID} has been closed.`);
    },
    openShowMore() {
      this.showMore = !this.showMore;
    },
    beforeCalling() {
      this.showCall = true;
    },
    // afterCalling：结束通话后执行
    afterCalling() {
      this.showCall = false;
      this.isMinimized = false;
    },
    // onMinimized：组件切换最小化状态时执行
    onMinimized(oldMinimizedStatus, newMinimizedStatus) {
      this.isMinimized = newMinimizedStatus;
      this.dragRef?.value?.positionReset && this.dragRef?.value?.positionReset();
    }
  },
  mounted() {
    window.addEventListener("message", (event) => {
      const data = event.data;
      if (data.type == 'switchTeacher') {
        if (data?.userInfo?.tim_id && data?.userInfo?.tim_id?.length > 0) {
          this.$store.commit("userInfo", data.userInfo);
          this.$store.commit("toInfo", data.toInfo);
          this.$store.commit("isTeacher", data.isTeacher);
          this.$store.commit("courseInfo", data.courseInfo);
          console.log("switchTeacher TIM STORE::", this.$store.state)
        }
      }
    });
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id) => {
        this.currentConversationID = id;
      },
    });
    console.log('this.$route', this.$route)
    if (this.$route.query.to_tim) {
      console.log("%c Line:237 🍏 to_tim", "color:#e41a6a", this.$route.query.to_tim);
      this.currentConversationID = 'C2C' + this.$route.query.to_tim;
      TUIConversationService.switchConversation(this.currentConversationID);
    }
  }
};
</script>

<style scoped lang="scss">
@import "../styles/home.scss";
@import "../styles/icon.scss";
</style>
