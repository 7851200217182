import { render, staticRenderFns } from "./index.vue?vue&type=template&id=d718b508&scoped=true&"
import script from "./index.vue?vue&type=script&lang=ts&setup=true&"
export * from "./index.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "../style/index.scss?vue&type=style&index=0&id=d718b508&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d718b508",
  null
  
)

export default component.exports