<template>
  <div class="chat">
    <div :class="['TUI-chat', !isPC && 'TUI-chat-h5']">
      <div v-if="!currentConversationID" :class="['TUI-chat-default', !isPC && 'TUI-chat-h5-default']">
        <slot></slot>
      </div>
      <div v-if="currentConversationID" :class="['TUI-chat', !isPC && 'TUI-chat-h5']">
        <ChatHeader :class="[
            'TUI-chat-header',
            !isPC && 'TUI-chat-H5-header',
            !isToolbarShow && 'TUI-chat-uniapp-header',
          ]" @closeChat="closeChat" @startOrderCourse="startOrderCourse"></ChatHeader>
        <MessageList :class="[
            'TUI-chat-message-list',
            !isPC && 'TUI-chat-h5-message-list',
          ]" :isGroup="isGroup" :groupID="groupID" @handleEditor="handleEditor">
        </MessageList>
        <MessageInputToolbar v-show="isToolbarShow" :class="[
            'TUI-chat-message-input-toolbar',
            !isPC && 'TUI-chat-h5-message-input-toolbar',
          ]" @insertEmoji="insertEmoji"></MessageInputToolbar>
        <MessageInput :class="[
            'TUI-chat-message-input',
            !isPC && 'TUI-chat-h5-message-input',
            !isToolbarShow && 'TUI-chat-uni-message-input',
            isWeChat && 'TUI-chat-wx-message-input',
          ]" :isMuted="false" muteText="您已被管理员禁言" placeholder="请输入消息" ref="messageInputRef" @handleToolbarListShow="handleToolbarListShow"></MessageInput>
      </div>
      <!-- 群组管理 -->
      <div class="group-profile" v-if="!isToolbarShow && isGroup && groupManageExt" @click="handleGroup">
        {{ groupManageExt.text }}
        <!-- 更多 -->
      </div>
    </div>
  </div>
</template>
<script>
import TUIChatEngine, {
  TUIGlobal,
  TUIConversationService,
  TUIStore,
  StoreName,
  IMessageModel,
  IConversationModel,
} from "@tencentcloud/chat-uikit-engine";
import { ref, onUnmounted } from "../../adapter-vue";
import ChatHeader from "./chat-header/index.vue";
import MessageList from "./message-list/index.vue";
import MessageInput from "./message-input/index.vue";
import MessageInputToolbar from "./message-input-toolbar/index.vue";
import TUICore, { TUIConstants, ExtensionInfo } from "@tencentcloud/tui-core";
import { isUniFrameWork } from "../../utils/is-uni";
export default {
  name: "TUIChat",
  components: {
    ChatHeader, MessageList, MessageInput, MessageInputToolbar
  },
  data() {
    return {
      isPC: false,
      isWeChat: false,
      isToolbarShow: false,
      messageInputRef: null,
      currentConversationID: null,
      isGroup: false,
      groupID: "",
      groupManageExt: undefined,
    };
  },
  methods: {
    reset() {
      TUIConversationService.switchConversation();
    },
    closeChat(conversationID) {
      this.$emit("closeChat", conversationID);
      this.reset();
    },
    startOrderCourse(obj) {
      console.log("%c Line:126 🥓 startOrderCourse", "color:#e41a6a", obj);
      this.$emit("startOrderCourse", obj);
    },
    insertEmoji(emojiObj) {
      this.messageInputRef?.value?.insertEmoji(emojiObj);
    },
    handleToolbarListShow(show) {
      if (this.isUniFrameWork) {
        this.isToolbarShow = show ?? !this.isToolbarShow;
      } else {
        this.isToolbarShow = true;
      }
    },
    handleEditor(message, type) {
      if (!message || !type) return;
      switch (type) {
        case "reference":
          // todo
          break;
        case "reply":
          // todo
          break;
        case "reedit":
          if (message?.payload?.text) {
            this.messageInputRef?.reEdit(message?.payload?.text);
          }
          break;
        default:
          break;
      }
    },
    handleGroup() {
      this.groupManageExt.listener.onClicked({ groupID: groupID.value });
    }
  },
  mounted() {
    TUIStore.watch(StoreName.CONV, {
      currentConversationID: (id) => {
        this.currentConversationID = id;
      },
      currentConversation: (conversation) => {
        this.isGroup = conversation?.type === TUIChatEngine.TYPES.CONV_GROUP;
        this.groupID = conversation?.groupProfile?.groupID;
        const extList = TUICore.getExtensionList(
          TUIConstants.TUIChat.EXTENSION.CHAT_HEADER.EXT_ID,
          { filterManageGroup: this.isGroup }
        );
        this.groupManageExt = extList[0];
      },
    });
    this.isPC = TUIGlobal.getPlatform() === "pc";
    console.log("%c Line:133 🍢 this.isPC", "color:#4fff4B", this.isPC);
    this.isWeChat = TUIGlobal.getPlatform() === "wechat";
    this.isToolbarShow = !isUniFrameWork;
  },
  onUnmounted() {
    reset();
  },
};
</script>
<style scoped lang="scss" src="./style/index.scss"></style>
