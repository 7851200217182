import Vue from 'vue'
import Vuex from 'vuex'
import cookies from "js-cookie";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: {},
    toInfo: {},
    isTeacher: false,
    courseInfo: {}
  },
  getters: {
    userInfo(state) {
      if (typeof window !== "undefined" && JSON.stringify(state.userInfo) === '{}' && cookies.get('userInfo')) {
        state.userInfo = JSON.parse(cookies.get('userInfo'));
      }
      return state.userInfo;
    },
    toInfo(state) {
      if (typeof window !== "undefined" && JSON.stringify(state.toInfo) === '{}' && cookies.get('toInfo')) {
        state.toInfo = JSON.parse(cookies.get('toInfo'));
      }
      return state.toInfo;
    },
    isTeacher(state) {
      if (typeof window !== "undefined" && JSON.stringify(state.isTeacher) === '{}' && cookies.get('isTeacher')) {
        state.isTeacher = JSON.parse(cookies.get('isTeacher'));
      }
      return state.isTeacher;
    },
    courseInfo(state) {
      if (typeof window !== "undefined" && JSON.stringify(state.courseInfo) === '{}' && cookies.get('courseInfo')) {
        state.courseInfo = JSON.parse(cookies.get('courseInfo'));
      }
      return state.courseInfo;
    },
  },
  mutations: {
    userInfo(state, value) {
      state.userInfo = value;
      cookies.set('userInfo', JSON.stringify(state.userInfo));
    },
    toInfo(state, value) {
      state.toInfo = value;
      cookies.set('toInfo', JSON.stringify(state.toInfo));
    },
    isTeacher(state, value) {
      state.isTeacher = value;
      cookies.set('isTeacher', JSON.stringify(state.isTeacher));
    },
    courseInfo(state, value) {
      state.courseInfo = value;
      cookies.set('courseInfo', JSON.stringify(state.courseInfo));
    },
  },
  actions: {
  },
  modules: {
  }
})
