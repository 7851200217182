import { render, staticRenderFns } from "./message-input-editor.vue?vue&type=template&id=c53cca82&scoped=true&"
import script from "./message-input-editor.vue?vue&type=script&setup=true&lang=ts&"
export * from "./message-input-editor.vue?vue&type=script&setup=true&lang=ts&"
import style0 from "./message-input-editor.vue?vue&type=style&index=0&id=c53cca82&prod&scoped=true&lang=scss&"
import style1 from "./message-input-editor.vue?vue&type=style&index=1&id=c53cca82&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c53cca82",
  null
  
)

export default component.exports