var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['TUI-profile', !_setup.isPC && 'TUI-profile-h5']},[(_vm.displayType !== 'setting')?_c('div',{class:['TUI-profile-basic', !_setup.isPC && 'TUI-profile-h5-basic']},[_c('img',{class:[
        'TUI-profile-basic-avatar',
        !_setup.isPC && 'TUI-profile-h5-basic-avatar',
      ],attrs:{"src":_setup.userProfile.avatar ||
        'https://web.sdk.qcloud.com/component/TUIKit/assets/avatar_21.png'}}),_c('div',{class:[
        'TUI-profile-basic-info',
        !_setup.isPC && 'TUI-profile-h5-basic-info',
      ]},[_c('div',{class:[
          'TUI-profile-basic-info-nick',
          !_setup.isPC && 'TUI-profile-h5-basic-info-nick',
        ]},[_vm._v(" "+_vm._s(_setup.userProfile.nick || "-")+" ")]),_c('div',{class:[
          'TUI-profile-basic-info-id',
          !_setup.isPC && 'TUI-profile-h5-basic-info-id',
        ]},[_c('label',{class:[
            'TUI-profile-basic-info-id-label',
            !_setup.isPC && 'TUI-profile-h5-basic-info-id-label',
          ]},[_vm._v(_vm._s(_setup.TUITranslateService.t("TUIProfile.用户ID"))+":")]),_c('div',{class:[
            'TUI-profile-basic-info-id-value',
            !_setup.isPC && 'TUI-profile-h5-basic-info-id-value',
          ]},[_vm._v(" "+_vm._s(_setup.userProfile.userID)+" ")])])])]):_vm._e(),(_vm.displayType !== 'profile' && (!_setup.isPC || _vm.showSetting))?_c('div',{ref:"settingDomRef",class:['TUI-profile-setting', !_setup.isPC && 'TUI-profile-h5-setting'],on:{"click":function($event){$event.stopPropagation();}}},_vm._l((_setup.settingList),function(item){return _c('div',{key:item.value,class:[
        'TUI-profile-setting-item',
        !_setup.isPC && 'TUI-profile-h5-setting-item',
        item.value === 'exit' && 'TUI-profile-h5-setting-item-exit',
      ]},[_c('div',{class:[
          'TUI-profile-setting-item-label',
          !_setup.isPC && 'TUI-profile-h5-setting-item-label',
        ],on:{"click":function($event){return _setup.handleSettingListItemOnClick(item)}}},[_c('div',{class:['label-left']},[_c('div',{class:['label-title']},[_vm._v(" "+_vm._s(item.label)+" ")]),(
              item.children && !_setup.isPC && item.childrenShowType === 'switch'
            )?_c('div',{class:['label-desc']},[_vm._v(" "+_vm._s(item.value)+" ")]):_vm._e()]),_c('div',{class:['label-right']},[(
              !_setup.isPC &&
              item.children &&
              item.selectedChild &&
              item.childrenShowType === 'bottomPopup'
            )?_c('div',{class:[
              'TUI-profile-setting-item-label-value',
              !_setup.isPC && 'TUI-profile-h5-setting-item-label-value',
            ]},[_vm._v(" "+_vm._s(item?.children[item.selectedChild]?.label)+" ")]):_vm._e(),(item.children)?_c(_setup.Icon,{attrs:{"file":_setup.rightArrowIcon,"width":"14px","height":"14px"}}):_vm._e()],1)]),(item.children && _setup.isPC)?_c('div',{class:[
          'TUI-profile-setting-item-children',
          !_setup.isPC && 'TUI-profile-h5-setting-item-children',
        ]},_vm._l((item.children),function(child){return _c('div',{key:child.value,class:[
            'TUI-profile-setting-item-children-item',
            !_setup.isPC && 'TUI-profile-h5-setting-item-children-item',
          ],on:{"click":function($event){return _setup.handleSettingListItemOnClick(child)}}},[_c('div',{class:[
              'TUI-profile-setting-item-children-item-label',
              !_setup.isPC && 'TUI-profile-h5-setting-item-children-item-label',
            ]},[_vm._v(" "+_vm._s(child.label)+" ")]),(item.selectedChild === child.value)?_c(_setup.Icon,{attrs:{"file":_setup.selectedIcon,"width":"14px","height":"14px"}}):_vm._e()],1)}),0):_vm._e(),(
          item.children && !_setup.isPC && item.childrenShowType === 'bottomPopup'
        )?_c(_setup.BottomPopup,{attrs:{"show":item.showChildren},on:{"onClose":function($event){item.showChildren = false}}},_vm._l((item.children),function(child){return _c('div',{key:child.value,class:[
            'TUI-profile-setting-item-bottom-popup',
            !_setup.isPC && 'TUI-profile-h5-setting-item-bottom-popup',
          ],on:{"click":function($event){return _setup.handleSettingListItemOnClick(child)}}},[_vm._v(" "+_vm._s(child.label)+" ")])}),0):_vm._e()],1)}),0):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }