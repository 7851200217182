var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"message-video"},[_c('div',{ref:"skeleton",staticClass:"message-video-box",class:[
      (!_setup.props.progress || _setup.props.progress === 1) &&
        !_vm.isPC &&
        'message-video-cover',
    ],on:{"click":_setup.showVideoPreviewer}},[(
        (_setup.props.progress > 0 && _setup.props.progress < 1 && _setup.poster) ||
        (!_vm.isPC && _setup.poster)
      )?_c('img',{staticClass:"message-img",class:[_setup.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_setup.poster}}):(!_vm.isPC)?_c('video',{ref:"videoRef",staticClass:"message-img video-h5-uploading",attrs:{"src":_setup.data.url + '#t=0.1',"poster":_setup.data.url,"preload":"auto","muted":""},domProps:{"muted":true}}):_c('video',{ref:"videoRef",staticClass:"message-img video-web",attrs:{"src":_setup.data.url,"controls":"","preload":"metadata","poster":_setup.poster}}),(_setup.props.progress > 0 && _setup.props.progress < 1)?_c('div',{staticClass:"progress"},[_c('progress',{attrs:{"max":"1"},domProps:{"value":_setup.props.progress}})]):_vm._e()]),(_setup.show && !_vm.isPC)?_c('div',{staticClass:"dialog-video",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.showVideoPreviewer.apply(null, arguments)}}},[_c('div',{staticClass:"dialog-video-close",on:{"click":function($event){$event.stopPropagation();return _setup.showVideoPreviewer.apply(null, arguments)}}},[_c(_setup.Icon,{attrs:{"file":_setup.closeSVG}})],1),_c('div',{staticClass:"dialog-video-box",class:[!_vm.isPC ? 'dialog-video-h5' : ''],on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _setup.showVideoPreviewer.apply(null, arguments)}}},[_c('video',{class:[_setup.isWidth ? 'isWidth' : 'isHeight'],attrs:{"src":_setup.data.url,"controls":"","autoplay":""}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }