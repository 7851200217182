<template>
  <div>
    <Dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>这是一段信息</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </Dialog>
  </div>
</template>

<script>
import { Dialog } from 'element-ui'
export default {
  name: 'orderCourse',
  components: { Dialog },
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    startOrderCourse() {
      this.dialogVisible = true;
    },
    handleClose(done) {
      //   this.$confirm('确认关闭？')
      //     .then(_ => {
      //       done();
      //     })
      //     .catch(_ => { });
    }
  }
};
</script>

<style lang="scss" scoped>
</style>